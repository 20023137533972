import React, { Component } from 'react';
import classNames from 'classnames';
import { ChevronDown, ChevronRight, ChevronLeft } from 'react-feather';
import OutsideClickHandler from 'react-outside-click-handler';


class Pagination extends Component {
  state = {
    showPageLimit: false,
    totalPage: 30,
    limit: this.props.limit,
    currentPage: 1,
  };

  getSnapshotBeforeUpdate(prevProps) {
    const { limit } = this.props;

    if (limit !== prevProps.limit) {
      return limit;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.setState({ limit: snapshot });
    }
  }

  hidePageLimit = () => {
    this.setState({ showPageLimit: false });
  };

  showPageLimit = (e) => {
    this.setState({ showPageLimit: true });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage });
    this.props.setCurrentPage(currentPage);
  }

  onPrevious = () => {
    if (this.props.currentPage !== 1) {
      this.setState({ currentPage: this.props.currentPage - 1 });
      this.props.setCurrentPage(this.props.currentPage - 1);
    }
  }

  onNext = () => {
    const { limit, totalPage, currentPage } = this.props;

    if ((currentPage * limit) < totalPage) {
      this.setState({ currentPage: currentPage + 1 });
      this.props.setCurrentPage(currentPage + 1);
    }
  }

  setLimit = (payload, e) => {
    this.props.onChangeLimit(payload);
    this.setState({ limit: payload });
  }

  render() {
    const { limit } = this.state;
    const { totalPage, currentPage, hideDivider } = this.props;
    const totalLimit = limit * currentPage;

    if (totalPage <= 10) {
      return <span />
    }

    return (
      <>
        {!hideDivider && <hr className="pagination-hr" />}
        <div className="pagination" onMouseDown={(e) => {
          e.stopPropagation(); // Prevents drag start
        }}>
          <OutsideClickHandler onOutsideClick={this.hidePageLimit}>
            <span className="pagination-item">Items per page</span>
            <span className="pagination-page-section">
              <span
                className="pagination-page-limit"
                onClick={this.showPageLimit}
              >
                <span className="current-page">{limit}</span>
                <span className="pagination-svg">
                  <ChevronDown size={16} />
                </span>
              </span>
              {this.state.showPageLimit && (
                <div
                  className="pagination-selector"
                  onClick={this.hidePageLimit}
                >
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(10)}>10</h4>
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(25)}>25</h4>
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(50)}>50</h4>
                  <h4 className="pagination-selector-item" onClick={() => this.setLimit(100)}>100</h4>
                </div>
              )}
            </span>
            <span className="pagination-info">
              {((currentPage - 1) * limit) + 1}
              -
              {totalPage > totalLimit ? totalLimit : totalPage}
              {' '}
              of
              {' '}
              {totalPage}
            </span>
            {' '}
            <span
              className={classNames('pagination-prev', {
                'pagination-disabled': currentPage === 1,
              })}
              onClick={this.onPrevious}
            >
              <ChevronLeft size={16} />
            </span>
            <span
              onClick={this.onNext}
              className={classNames('pagination-next', {
                'pagination-disabled': (totalLimit) >= totalPage,
              })}
            >
              <ChevronRight size={16} />
            </span>
          </OutsideClickHandler>
        </div>
      </>
    );
  }
}

export default Pagination;
