import {
  LIST_DASHBOARDS_ERROR,
  LIST_DASHBOARDS_REQUEST,
  LIST_DASHBOARDS_SUCCESS,
  DETAILS_DASHBOARDS_ERROR,
  DETAILS_DASHBOARDS_REQUEST,
  DETAILS_DASHBOARDS_SUCCESS,
  LIST_INCIDENT_ERROR,
  LIST_INCIDENT_REQUEST,
  LIST_INCIDENT_SUCCESS ,
  KANBAN_INCIDENT_REQUEST
} from './constants';


export function getDashboardListPending(payload) {
  return {
    type: LIST_DASHBOARDS_REQUEST,
    payload,
  };
}

export function getDashboardListSuccess(payload) {
  return {
    type: LIST_DASHBOARDS_SUCCESS,
    payload,
  };
}

export function getDashboardListError(errors) {
  return {
    type: LIST_DASHBOARDS_ERROR,
    errors,
  };
}

export function getDashboardPending(payload) {
  return {
    type: DETAILS_DASHBOARDS_REQUEST,
    payload,
  };
}

export function getDashboardSuccess(payload) {
  return {
    type: DETAILS_DASHBOARDS_SUCCESS,
    payload,
  };
}

export function getDashboardError(payload) {
  return {
    type: DETAILS_DASHBOARDS_ERROR,
    payload,
  };
}


export function getIncidentPending(payload) {
  return {
    type: LIST_INCIDENT_REQUEST,
    payload,
  };
}

export function getIncidentKanbanPending(payload) {
  return {
    type: KANBAN_INCIDENT_REQUEST,
    payload,
  };
}

export function getIncidentSuccess(payload) {
  return {
    type: LIST_INCIDENT_SUCCESS,
    payload,
  };
}

export function getIncidentError(payload) {
  return {
    type: LIST_INCIDENT_ERROR,
    payload,
  };
}
