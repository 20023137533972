import React from 'react';
import { routeMapping } from 'api/constants';

import loadable from '@loadable/component';

const DeviceDetail = loadable(() => import('containers/Device'));
const DeviceHealth = loadable(() => import('containers/Device/components/DeviceHealthDetails'));
const DeviceTerminal = loadable(() => import('containers/Device/components/DeviceTerminal'));
const LibraryApplication = loadable(() => import('containers/Library/Application'));
const LibraryModel = loadable(() => import('containers/Library/Model'));
const LibraryDataset = loadable(() => import('containers/Library/Dataset'));
const LibraryEnvironment = loadable(() => import('containers/Library/Environment'));
const LibraryExperiment = loadable(() => import('containers/Library/Experiments'));
const LibraryAnnotationTask = loadable(() => import('containers/Library/AnnotationTask'));
const LibraryEnvironmentJupyterLab = loadable(() => import('containers/Library/Environment/ModelTraining'));
const LibraryEnvironmentDetails = loadable(() => import('containers/Library/Experiments/Details'));
const LibraryModelCreate = loadable(() => import('containers/Library/ModelDetails/Create/AddModel'));
const LibraryExperimentCreate = loadable(() => import('containers/Library/Experiments/Create/Add'));
const LibraryAnnotationTaskCreate = loadable(() => import('containers/Library/AnnotationTask/Create/Add'));
const LibraryJobTraining = loadable(() => import('containers/Library/Experiments/JobTraining'));
const LibraryAnnotationJob = loadable(() => import('containers/Library/AnnotationTask/AnnotationJob'));
const LibraryEnvironmentCreate = loadable(() => import('containers/Library/Environment/Create/Add'));
const LibraryVideos = loadable(() => import('containers/Library/Videos/index'));
const ApplicationDetail = loadable(() => import('containers/Library/ApplicationDetail'));
const ModelDetails = loadable(() => import('containers/Library/ModelDetails'));
const DatasetDetails = loadable(() => import('containers/Library/DatasetDetails'));
const DatasetCvat = loadable(() => import('containers/Library/DatasetDetails/Cvat'));
const CreateDataset = loadable(() => import('containers/Library/DatasetDetails/Create'));
const CloneApplication = loadable(() => import('containers/Library/ApplicationDetail/Edit/Clone'));
const ImportModule = loadable(() => import('containers/Library/ModuleProcess/CreateModule'));
const CustomModule = loadable(() => import('containers/Library/ModuleProcess/CustomModule'));
const PublicModule = loadable(() => import('containers/Library/ModuleProcess/PublicModule'));
const CommitConfiguration = loadable(() => import('containers/Library/ApplicationDetail/Edit/CommitConfiguration'));
const LibraryModules = loadable(() => import('containers/Library/modules'));
const ContainerManager = loadable(() => import('containers/Device/components/containerManager'));
const DeviceHealthCheck = loadable(() => import('containers/Device/deviceHealth'));
const Deployment = loadable(() => import('containers/Deployment'));
const ProfileDetail = loadable(() => import('containers/Deployment/ProfileDetail'));
const ModuleDetail = loadable(() => import('containers/Library/modules/Detail'));
const CreateApplication = loadable(() => import('containers/Library/ApplicationDetail/Create/CreateApplication'));
const ImportVideo = loadable(() => import('containers/Library/Videos/ImportVideo'));
const ViewVideo = loadable(() => import('containers/Library/Videos/VideoDetails'));
const EditVideoInfo = loadable(() => import('containers/Library/Videos/EditImportVideo'));
const CreateApplicationFromScratch = loadable(() => import('containers/Library/ApplicationDetail/Create'));
const CreateApplicationFromTemplate = loadable(() => import('containers/Library/ApplicationDetail/Create/ManageApp'));
const ImportApplication = loadable(() => import('containers/Library/ApplicationDetail/Create/ImportApplication'));
const HistoryPage = loadable(() => import('containers/Library/ApplicationDetail/components/History/list'));
const VersionPage = loadable(() => import('containers/Library/ApplicationDetail/components/Version/list'));
const CreateSolution = loadable(() => import('containers/Partner/solutions/Create'));
const CreateSolutionSelector = loadable(() => import('containers/Partner/solutions/Create/Selector'));
const Dashboard = loadable(() => import('containers/Dashboard/Loadable'));
const Dashboards = loadable(() => import('containers/CustomDashboard/Dashboard'));
const DashboardDetails = loadable(() => import('containers/CustomDashboard/Dashboard/details'));
const CreateDashboard = loadable(() => import('containers/CustomDashboard/Dashboard/create'));
const Settings = loadable(() => import('containers/Settings/Loadable'));
const Incident = loadable(() => import('screens/Incidents'));
// const UserGroupDetail = loadable(() => import('containers/Settings/UserGroup/Detail/Loadable'));
// const UserGroupEdit = loadable(() => import('containers/Settings/UserGroup/Detail/Edit'));
const RoleDetail = loadable(() => import('containers/Settings/AccessRole/Detail'));
const RoleSettings = loadable(() => import('containers/Settings/AccessRole'));
const UserSettings = loadable(() => import('containers/Settings/User'));
// const UserGroup = loadable(() => import('containers/Settings/UserGroup'));
const UserWorkspace = loadable(() => import('containers/Settings/Workspace'));
const LicenseList = loadable(() => import('containers/License/List'));
const LicenseDetails = loadable(() => import('containers/License/Details'));
const UserDetail = loadable(() => import('containers/Settings/User/Detail/Loadable'));
const Workspace = loadable(() => import('containers/Settings/Workspace/Loadable'));
const ManageWorkspace = loadable(() => import('containers/Settings/Workspace/Manage'));
const CreateWorkspace = loadable(() => import('containers/Settings/Workspace/Create'));
const SelectWorkspace = loadable(() => import('containers/Settings/Workspace/Select'));
const RequiredModules = loadable(() => import('containers/Settings/Workspace/RequiredModules'));
const ConfirmMarketplaceOrder = loadable(() => import('containers/Settings/Workspace/ConfirmMarketplaceOrder'));
const CreateDeviceType = loadable(() => import('containers/Deployment/CreateDevice'));
const ImageVersionManager = loadable(() => import('containers/Deployment/SelectDeviceType/VersionManager'));
const PendingDeviceType = loadable(() => import('containers/Deployment/SelectDeviceType/PendingDevicesContainer'));
const CreateImage = loadable(() => import('containers/Deployment/CreateDevice/CreateImage'));
const Permissions = loadable(() => import('containers/Settings/AccessRole/Permissions'));
const InvitationList = loadable(() => import('containers/Settings/User/invitedUsers'));
const Billing = loadable(() => import('containers/Billing'));
const Partner = loadable(() => import('containers/Partner'));
const NewPartner = loadable(() => import('containers/Partner/overview/NewPartner'));
const Solutions = loadable(() => import('containers/Partner/solutions'));
const ManageSubscription = loadable(() => import('containers/Billing/components/details/manageSubscription/index'));
const SolutionDetails = loadable(() => import('containers/Partner/solutions/components/details'));
const PermissionSelector = loadable(() => import('components/Permission/PermissionSelector'));
const NodeDetails = loadable(() => import('containers/Nodes/NodeDetails'));
const Marketplace = loadable(() => import('containers/Marketplace'));
const SolutionPreview = loadable(() => import('containers/Partner/solutions/components/details/SolutionPreview'));
const SetNewPassword = loadable(() => import('containers/Auth/ForgotPassword'));
const ChangePlan = loadable(() => import('containers/Billing/components/details/manageSubscription/ChangePlan'));
const TerminalAccess = loadable(() => import('containers/TerminalAccess/TerminalContainer'));
const BillingQuota = loadable(() => import('containers/Billing/components/details/PlanQuotaSingle'));
const VideoViewerContainer = loadable(() => import('./containers/VideoViewer/VideoViewerContainer'));
const AuditLogContainer = loadable(() => import('./containers/Settings/Audit/index'));
const NoCodeEdit = loadable(() => import('./containers/Library/Application/OpenNoCode'));
const NoCode = loadable(() => import('./containers/Library/Application/NoCode'));
const AuthenticationMain = loadable(() => import('./containers/Settings/User/Detail/components/AuthenticationMainSingle'));
const ConfigurationManager = loadable(() => import('./containers/Deployment/configurationManager'));
const HomePage = loadable(() => import('./containers/HomePage'));

export function pageDoesNotRequireLogin() {
  return (
    window.location.pathname === routeMapping.LOGIN.path ||
    window.location.pathname === '/portal-dashboards' ||
    window.location.pathname === '/portal-dashboards/'
  );
}

const routes = [
  {
    path: '/:slug',
    exact: true,
    name: 'Homepage',
    component: <HomePage/>
  },
  {
    path: '/not-found',
    exact: true,
    name: 'Not Found',
    component: <Settings/>
  },
  {
    path: routeMapping.LICENSE_LIST.path,
    exact: true,
    id: 'licenses',
    name: 'Licenses',
    component: <LicenseList/>
  },
  {
    path: '/:slug/change-plan',
    exact: true,
    id: 'changePlan',
    name: 'ChangePlan',
    component: <ChangePlan/>
  },
  {
    path: `/:slug${routeMapping.SETTINGS_USER.path}`,
    name: 'Users',
    id: 'users',
    exact: true,
    component: <UserSettings/>
  },
  {
    path: '/permissions',
    name: 'Permission',
    id: 'permission',
    exact: true,
    component: <Permissions/>
  },
  {
    path: `/:slug${routeMapping.PENDING_DEVICE_TYPE_LIST.path}`,
    name: 'DeviceType',
    exact: true,
    component: <PendingDeviceType/>
  },
  {
    path: `/:slug${routeMapping.SETTING_DEVICE_TYPE_LIST.path}`,
    name: 'DeviceType',
    exact: true,
    component: <CreateDeviceType/>
  },
  {
    path: `/:slug${routeMapping.SETTING_DEVICE_TYPE_DETAILS.path}`,
    name: 'ImageVersionManager',
    component: <ImageVersionManager/>
  },
  {
    path: `/:slug${routeMapping.CREATE_DEVICE_IMAGE.path}`,
    name: 'DeviceImage',
    exact: true,
    component: <CreateImage/>
  },
  {
    path: `/:slug${routeMapping.SETTINGS_ROLE.path}`,
    name: 'User Role',
    id: 'userRole',
    exact: true,
    component: <RoleSettings/>
  },
  {
    path: `/:slug${routeMapping.APPLICATIONS_NO_CODE_PAGE.path}`,
    name: 'No Code',
    id: 'noCode',
    exact: true,
    component: <NoCode/>
  },
  {
    path: `/:slug${routeMapping.APPLICATIONS_NO_CODE_EDIT_PAGE.path}`,
    name: 'No Code Edit',
    id: 'noCodeEdit',
    exact: true,
    component: <NoCodeEdit/>,
  },
  // {
  //   path: routeMapping.SETTINGS_GROUP.path,
  //   name: 'User Group',
  //   exact: true,
  //   component: <UserGroup/>
  // },
  {
    path: `/:slug${routeMapping.WORKSPACE.path}`,
    name: 'User Workspace',
    exact: true,
    component: <UserWorkspace/>
  },
  {
    path: `/:slug${routeMapping.DEVICES_DETAIL.path}`,
    name: 'Device Detail',
    exact: true,
    component: <DeviceDetail/>
  },
  {
    path: `/:slug${routeMapping.PROFILE_DETAIL.path}`,
    name: 'Profile Detail',
    exact: true,
    component: <ProfileDetail/>
  },
  {
    path: '/:slug/deployment',
    name: 'Deployment',
    id: 'deployment',
    exact: true,
    component: <Deployment/>
  },
  {
    path: `/:slug${routeMapping.SUBSCRIBE_MARKETPLACE.path}`,
    name: 'Subscribe Marketplace',
    id: 'subscribe marketplace',
    exact: true,
    component: <ConfirmMarketplaceOrder/>
  },
  {
    path: `/:slug${routeMapping.REQUIRED_MODULES.path}`,
    name: 'Required Modules',
    id: 'required modules',
    exact: true,
    component: <RequiredModules/>
  },
  {
    path: `/:slug${routeMapping.DEPLOYMENT_PROFILES.path}`,
    name: 'Profiles',
    id: 'deploymentCenter',
    exact: true,
    component: <Deployment/>
  },
  {
    path: `/:slug${routeMapping.DEPLOYMENT_DEVICES.path}`,
    name: 'Devices',
    id: 'deviceManagement',
    exact: true,
    component: <Deployment/>
  },
  {
    path: `/:slug${routeMapping.DEPLOYMENT_DEVICES_MANAGER.path}`,
    name: 'Configuration Manager',
    id: 'configurationManager',
    exact: true,
    component: <ConfigurationManager/>
  },
  {
    path: `/:slug${routeMapping.DEVICES_ANALYTIC.path}`,
    exact: true,
    name: 'Device Health',
    id: 'deviceHealth',
    component: <DeviceHealth/>
  },
  {
    path: `/:slug${routeMapping.DEVICE_CONTAINER_MANAGER.path}`,
    exact: true,
    name: 'Device Container Manager',
    id: 'containerManager',
    component: <ContainerManager/>
  },
  {
    path: `/:slug${routeMapping.DEVICE_HEALTH_CHECK.path}`,
    exact: true,
    name: 'Device Health Check',
    id: 'healthCheck',
    component: <DeviceHealthCheck/>
  },

  {
    path: `/:slug${routeMapping.DEVICE_TERMINAL.path}`,
    name: 'Device Terminal',
    id: 'deviceHealth',
    component: <DeviceTerminal/>
  },
  {
    path: `/:slug${routeMapping.NEW_LIBRARY_APPLICATION.path}`,
    name: 'Create Library',
    exact: true,
    component: <CreateApplication/>
  },
  {
    path: `/:slug${routeMapping.NEW_LIBRARY_VIDEO.path}`,
    name: 'Import Video',
    exact: true,
    component: <ImportVideo/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_VIDEO.path}`,
    name: 'View Video',
    exact: true,
    component: <ViewVideo/>
  },
  {
    path: `/:slug${routeMapping.NEW_LIBRARY_APPLICATION_SCRATCH.path}`,
    name: 'Create Library From Scratch',
    exact: true,
    component: <CreateApplicationFromScratch/>
  },
  {
    path: `/:slug${routeMapping.NEW_LIBRARY_APPLICATION_TEMPLATE.path}`,
    name: 'Create Library From Template',
    exact: true,
    component: <CreateApplicationFromTemplate/>
  },
  {
    path: `/:slug${routeMapping.NEW_LIBRARY_APPLICATION_IMPORT.path}`,
    name: 'Import Library',
    exact: true,
    id: 'importLibrary',
    component: <ImportApplication/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_VIDEOS.path}/:id`,
    name: 'Edit Video Detail',
    exact: true,
    component: <EditVideoInfo/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_APPLICATION.path}`,
    name: 'Application Detail',
    exact: true,
    component: <ApplicationDetail/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY.path}`,
    name: 'Library',
    id: 'library',
    exact: true,
    component: <LibraryApplication/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_MODELS.path}`,
    name: 'AI Models',
    id: 'aiModels',
    exact: true,
    component: <LibraryModel/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_DATASETS_CVAT_CREATE.path}`,
    name: 'Dataset Cvat',
    component: <DatasetCvat/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_DATASETS.path}`,
    name: 'Datasets',
    id: 'datasets',
    exact: true,
    component: <LibraryDataset/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_ENVIRONMENTS.path}`,
    name: 'Environments',
    id: 'environments',
    exact: true,
    component: <LibraryEnvironment/>
  },
  {
    path: `/:slug${routeMapping.EXPERIMENTS.path}`,
    name: 'Experiments',
    id: 'experiment',
    exact: true,
    component: <LibraryExperiment/>
  },
  {
    path: `/:slug${routeMapping.ANNOTATION_TAKS.path}`,
    name: 'AnnotationTask',
    id: 'annotationTask',
    exact: true,
    component: <LibraryAnnotationTask/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_IMAGE_ANNOTATION.path}`,
    name: 'Environments',
    id: 'environments',
    exact: true,
    component: <LibraryEnvironment/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_MODEL_TRAINING.path}`,
    name: 'Environments',
    id: 'environments',
    exact: true,
    component: <LibraryEnvironmentJupyterLab/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_JOB_TRAINING.path}`,
    name: 'Job Training',
    id: 'jobTraining',
    exact: true,
    component: <LibraryJobTraining/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_ANNOTATION_JOB.path}`,
    name: 'Annotation Job',
    id: 'annotationJob',
    exact: true,
    component: <LibraryAnnotationJob/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_JOB_TRAINING.path}/:id`,
    name: 'Job Training',
    id: 'jobTraining',
    exact: true,
    component: <LibraryEnvironmentDetails/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_EXPERIMENT_CREATE.path}`,
    name: 'Library',
    id: 'library',
    exact: true,
    component: <LibraryExperimentCreate/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_ANNOTATION_TASK_CREATE.path}`,
    name: 'Library',
    id: 'library',
    exact: true,
    component: <LibraryAnnotationTaskCreate/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_MODELS_CREATE.path}`,
    name: 'Library',
    id: 'library',
    exact: true,
    component: <LibraryModelCreate/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_DATASET_CREATE.path}`,
    name: 'Library',
    id: 'library',
    exact: true,
    component: <CreateDataset/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_ENVIRONMENT_CREATE.path}`,
    name: 'Library',
    id: 'library',
    exact: true,
    component: <LibraryEnvironmentCreate/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_MODELS.path}/:id`,
    name: 'Model Details',
    component: <ModelDetails/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_DATASETS.path}/:id`,
    name: 'Dataset Details',
    component: <DatasetDetails/>
  },
  {
    path: `/:slug${routeMapping.IMPORT_APPLICATION.path}`,
    name: 'Clone application',
    component: <CloneApplication/>
  },
  {
    path: `/:slug${routeMapping.IMPORT_MODULE.path}`,
    name: 'Import module',
    component: <ImportModule/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_CUSTOM_CREATE.path}`,
    name: 'Import custom module',
    component: <CustomModule/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_CUSTOM_MODULE_LIST.path}`,
    name: 'Public module list',
    component: <PublicModule/>
  },
  {
    path: `/:slug${routeMapping.APPLICATIONS_COMMIT_PAGE.path}`,
    name: 'Application commit',
    component: <CommitConfiguration/>
  },
  {
    path: `/:slug${routeMapping.APPLICATIONS_HISTORY_PAGE.path}`,
    name: 'Application history',
    component: <HistoryPage/>
  },
  {
    path: `/:slug${routeMapping.APPLICATIONS_VERSION_PAGE.path}`,
    name: 'Application version',
    component: <VersionPage/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_APPLICATIONS.path}`,
    name: 'Applications',
    id: 'applications',
    exact: true,
    component: <LibraryApplication/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_VIDEOS.path}`,
    name: 'Videos',
    id: 'videos',
    exact: true,
    component: <LibraryVideos/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_MODULES.path}`,
    name: 'Building Blocks',
    id: 'buildingBlocks',
    exact: true,
    component: <LibraryModules/>
  },
  {
    path: `/:slug${routeMapping.LIBRARY_MODULE.path}`,
    name: 'Module Details',
    id: 'module_details',
    exact: true,
    component: <LibraryModules/>
  },
  {
    path: `/:slug${routeMapping.MODULE_DETAILS.path}`,
    name: 'Module Details',
    exact: true,
    component: <ModuleDetail/>
  },
  {
    path: `/:slug${routeMapping.APPLICATION_INFORMATION_EDIT.path}`,
    name: 'Edit Application Information',
    exact: true,
    component: <ModuleDetail/>
  },
  {
    path: `/:slug${routeMapping.INCIDENT.path}`,
    exact: true,
    name: 'Incidents',
    id: 'incident',
    component: <Incident/>
  },
  {
    path: `/:slug${routeMapping.DASHBOARD.path}`,
    exact: true,
    name: 'Monitor',
    id: 'monitor',
    component: <Dashboard/>
  },
  {
    path: `/:slug${routeMapping.DASHBOARDS.path}`,
    exact: true,
    name: 'Dashboards',
    id: 'dashboards',
    component: <Dashboards/>
  },
  {
    path: `/:slug${routeMapping.DASHBOARDS.path}/:id`,
    name: 'Dashboards',
    id: 'dashboards',
    component: <DashboardDetails/>
  },
  {
    path: `/:slug${routeMapping.CREATE_DASHBOARD.path}`,
    exact: true,
    name: 'Dashboards',
    id: 'dashboards',
    component: <CreateDashboard/>
  },
  {
    path: `/:slug${routeMapping.MARKETPLACE.path}`,
    exact: true,
    id: 'marketplace',
    name: 'Marketplace',
    component: <Marketplace/>
  },
  {
    path: `/:slug${routeMapping.MARKETPLACE.path}`,
    exact: true,
    id: 'module',
    name: 'Module',
    component: <Marketplace/>
  },
  {
    path: `/:slug${routeMapping.MARKETPLACE.path}`,
    exact: true,
    id: 'application',
    name: 'Application',
    component: <Marketplace/>
  },
  {
    path: `/:slug${routeMapping.PROFILE.path}`,
    exact: true,
    id: 'profile',
    name: 'Profile',
    component: <UserDetail/>
  },
  {
    path: `/:slug${routeMapping.USER_INVITATION_LIST.path}`,
    exact: true,
    name: 'User invitation list',
    component: <InvitationList/>
  }, {
    path: `/:slug${routeMapping.SETTINGS.path}/terminal`,
    id: 'terminal',
    name: 'Terminal Access',
    component: <TerminalAccess/>
  }, {
    path: `/:slug${routeMapping.SETTINGS.path}/video-access`,
    id: 'videoAccess',
    name: 'Video Access',
    component: <VideoViewerContainer/>
  }, {
    path: `/:slug${routeMapping.SETTINGS.path}/audit-log`,
    id: 'auditLog',
    name: 'Audit Log',
    component: <AuditLogContainer/>
  },
  {
    path: `/:slug${routeMapping.SETTINGS.path}`,
    exact: true,
    name: 'Settings',
    id: 'settings',
    component: <Settings/>
  },
  {
    path: `/:slug${routeMapping.BILLING.path}`,
    exact: true,
    id: 'billing',
    name: 'Billing',
    component: <Billing/>
  },
  {
    path: `/:slug${routeMapping.BILLING_QUOTA.path}`,
    exact: true,
    id: 'planQuota',
    name: 'Plan Quota',
    component: <BillingQuota/>
  },
  {
    path: `/:slug${routeMapping.PARTNER.path}`,
    exact: true,
    id: 'partner',
    name: 'Partner',
    component: <Partner/>
  },
  {
    path: `/:slug${routeMapping.PARTNER_NEW.path}`,
    exact: true,
    id: 'new_partner',
    name: 'New Partner',
    component: <NewPartner/>
  },
  {
    path: `/:slug${routeMapping.MANAGE_SUBSCRIPTION.path}`,
    exact: true,
    name: 'Billing Subscription manage',
    component: <ManageSubscription/>
  },
  {
    path: `/:slug${routeMapping.SETTINGS_TAB.path}`,
    exact: true,
    name: 'Settings tab',
    component: <Settings/>
  },
  {
    path: `/:slug${routeMapping.SETTINGS_ROLE.path}`,
    exact: true,
    id: 'userRoles',
    name: 'User Roles',
    component: <Settings/>
  },
  {
    path: `/:slug${routeMapping.SETTINGS_ROLE.path}`,
    exact: true,
    id: 'user',
    name: 'User',
    component: <Settings/>
  },
  {
    path: routeMapping.SETTINGS_GROUP.path,
    exact: true,
    name: 'User Groups',
    component: <Settings/>
  },
  // {
  //   path: routeMapping.SETTINGS_USER_GROUP_DETAIL.path,
  //   exact: true,
  //   name: 'User Group Detail',
  //   component: <UserGroupDetail/>
  // },
  // {
  //   path: routeMapping.SETTINGS_USER_GROUP_EDIT.path,
  //   exact: true,
  //   name: 'User Group Detail',
  //   component: <UserGroupEdit/>
  // },
  {
    path: routeMapping.SETTINGS_ROLE_MANAGE.path,
    exact: true,
    name: 'Role permission',
    component: <PermissionSelector/>
  },
  {
    path: `/:slug${routeMapping.ROLE_DETAIL.path}`,
    exact: true,
    name: 'Settings-Role',
    component: <RoleDetail/>
  },
  {
    path: `/:slug${routeMapping.SETTINGS_USER_DETAIL.path}`,
    exact: true,
    name: 'User Detail',
    component: <UserDetail/>
  },
  {
    path: `/:slug${routeMapping.NEW_WORKSPACE.path}`,
    exact: true,
    name: 'Workspace',
    id: 'workspace',
    component: <CreateWorkspace/>
  },
  {
    path: `/:slug${routeMapping.MANAGE_WORKSPACE.path}`,
    exact: true,
    id: 'myWorkspaces',
    name: 'Workspace manage',
    component: <ManageWorkspace/>
  },
  {
    path: routeMapping.SELECT_WORKSPACE.path,
    exact: true,
    name: 'Workspace',
    component: <SelectWorkspace/>
  },
  {
    path: `/:slug${routeMapping.WORKSPACE.path}`,
    exact: true,
    id: 'workspace',
    name: 'Workspace',
    component: <Workspace/>
  },
  {
    path: `/:slug${routeMapping.AUTHENTICATION.path}`,
    exact: true,
    id: 'authentication',
    name: 'Authentication',
    component: <AuthenticationMain/>
  },
  {
    path: routeMapping.LICENSE_DETAILS.path,
    exact: true,
    name: 'License',
    id: 'license',
    component: <LicenseDetails/>
  },
  {
    path: routeMapping.LICENSE_DETAILS.path,
    exact: true,
    name: 'License',
    component: <LicenseDetails/>
  },
  {
    path: `/:slug${routeMapping.NODE_DETAILS.path}`,
    exact: true,
    name: 'Node details',
    component: <NodeDetails/>
  },
  {
    path: routeMapping.MARKETPLACE_DETAILS.path,
    exact: true,
    name: 'Solution details',
    component: <SolutionPreview/>
  },
  {
    path: `/:slug${routeMapping.SOLUTION_PREVIEW.path}`,
    name: 'Solution details',
    component: <SolutionPreview/>
  },
  {
    path: routeMapping.RESET_PASSWORD.path,
    exact: true,
    name: 'Set password',
    component: <SetNewPassword/>
  },
  {
    path: `/:slug${routeMapping.CREATE_SOLUTION.path}`,
    exact: true,
    name: 'Create solution',
    component: <CreateSolutionSelector/>
  },
  {
    path: `/:slug${routeMapping.CREATE_SOLUTION_TYPE.path}`,
    name: 'Create solution type',
    component: <CreateSolution/>
  },
  {
    path: `/:slug${routeMapping.LIST_SOLUTION.path}`,
    exact: true,
    id: 'solutions',
    name: 'Solutions',
    component: <Solutions/>
  },
  {
    path: `/:slug${routeMapping.SOLUTION_DETAILS.path}`,
    name: 'Solution detail page',
    component: <SolutionDetails/>
  },
  {
    path: 'marketplace',
    exact: true,
    id: 'marketplace',
    name: 'Public Marketplace',
    component: <Marketplace/>
  },
  {
    path: 'marketplace',
    exact: true,
    id: 'application',
    name: 'Public Application',
    component: <Marketplace/>
  },
  {
    path: 'marketplace',
    exact: true,
    id: 'module',
    name: 'Public Module',
    component: <Marketplace/>
  },
]

export { routeMapping, routes }
