import {
  LOGOUT,
  LOADING,
  DISPLAY_FULL_SIDEBAR,
  DISPLAY_NOTIFICATION_BAR,
  LOGGED_IN_USER_DATA_REQUEST,
  LOGGED_IN_USER_DATA_SUCCESS,
  UPDATE_DEVICE_TYPE,
  FIRST_TIME_LOGGED_IN,
  DISPLAY_INFRA_BANNER,
  DISPLAY_FULL_DATASET_SIDEBAR,
  UPLOAD_TRACKER,
  UPDATE_SOCKET_ID,
  UPDATE_MODEL_URL
} from './constants';

const getCurrentDevice = ({ width }) => {
  // if (isMobile) {
  //   return 'mobile'
  // }
  if (width <= 575.98) return 'mobile';
  if (width > 575.98 && width < 991.97) return 'tablet';
  if (width > 991.98) return 'desktop';
  return 'desktop';
};

export const logout = () => ({
  type: LOGOUT,
});

export const setLoading = loading => ({
  type: LOADING,
  loading,
});

export function updateNotificationDisplay(payload) {
  return {
    type: DISPLAY_NOTIFICATION_BAR,
    payload,
  };
}

export function updateInfraBanner(payload) {
  return {
    type: DISPLAY_INFRA_BANNER,
    payload,
  };
}

export function isFirstLoggedIn(payload) {
  return {
    type: FIRST_TIME_LOGGED_IN,
    payload,
  };
}

export function updateDeviceType(payload) {
  return {
    type: UPDATE_DEVICE_TYPE,
    payload: getCurrentDevice(payload),
  };
}

export function updateSidebarDisplay(payload) {
  return {
    type: DISPLAY_FULL_SIDEBAR,
    payload,
  };
}

export function updateDatasetSidebarDisplay(payload) {
  return {
    type: DISPLAY_FULL_DATASET_SIDEBAR,
    payload,
  };
}

export function loggedInUserDataRequest(payload) {
  return {
    type: LOGGED_IN_USER_DATA_REQUEST,
    payload,
  };
}

export function loggedInUserDataSuccess(payload) {
  return {
    type: LOGGED_IN_USER_DATA_SUCCESS,
    payload,
  };
}

export function uploadTracker(payload, meta) {
  return {
    type: UPLOAD_TRACKER,
    payload,
    meta
  };
}

export function updateSocketId(payload) {
  return {
    type: UPDATE_SOCKET_ID,
    payload,
  };
}

export function addModelImage(payload) {
  let buildPayload = {}

  if (payload.payload) {
    const parsePayload = JSON.parse(payload.payload)
    buildPayload = {
      label: payload.message,
      current: parsePayload.current_frame,
      total: parsePayload.total_frames
    }
  }

  return {
    type: UPDATE_MODEL_URL,
    payload: buildPayload
  };
}